<template>
  <!-- Table Container Card -->
  <div>
    <b-card no-body>
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
          </b-col>
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                @input="filterTag"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
              <b-button
                variant="primary"
                v-if="(removeButtons = true)"
                v-b-modal.modal-tag-add
              >
                <span class="text-nowrap">Add Tag</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-modal
        id="edit-tag"
        no-close-on-backdrop
        hide-footer
        size="sm"
        centered
        title="Edit Tag"
      >
        <div class="modal_content">
          <b-form-group>
            <b-form-input
              v-model="editTag.name"
              placeholder="Edit Tag"
              type="text"
            />
          </b-form-group>
          <div class="modal-footer">
            <b-button
              @click="editTagFunction"
              style="margin-left: 7px"
              variant="primary"
              >Save
            </b-button>
          </div>
        </div>
      </b-modal>
      <b-modal
        id="modal-tag-add"
        hide-footer
        size="sm"
        centered
        title="Add Tag"
      >
        <div class="modal_content">
          <b-form-group>
            <b-form-input
              v-model="addTag.name"
              placeholder="Add Tag"
              type="text"
            />
          </b-form-group>
          <div class="modal-footer">
            <b-button
              @click="addTagFunc"
              :disabled="submitbutton"
              style="margin-left: 7px"
              variant="primary"
              >Add
            </b-button>
          </div>
        </div>
      </b-modal>
      <b-overlay :show="show" rounded="sm">
        <b-table
          :items="filterTagByName"
          responsive
          :fields="fields"
          class="mb-0"
          id="custom-table"
          :per-page="perPage"
          :current-page="currentPage"
        >
          <template #cell(index)="data">
            {{ data.index + 1 }}
          </template>

          <template #cell(tag)="data">
            {{ data.item.name }}
          </template>

          <template #cell(action)="data">
            <div class="d-flex align-items-center">
              <!-- {{ data.item.grade }} -->
              <b-dropdown variant="link" no-caret>
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>
                <b-dropdown-item>
                  <div
                    @click="editDataFromModel(data.item._id, data.item.name)"
                  >
                    <feather-icon icon="EditIcon" />
                    <span class="align-middle ml-50">Edit</span>
                  </div>
                </b-dropdown-item>
                <b-dropdown-item>
                  <div @click="deleteTagFromServer(data.item._id)">
                    <feather-icon icon="TrashIcon" />
                    <span class="align-middle ml-50">Delete</span>
                  </div>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </template>
        </b-table>
        <div style="display: flex; flex-direction: row-reverse">
          <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            aria-controls="custom-table"
            size="sm"
          ></b-pagination>
        </div>
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
  BOverlay,
  BFormGroup,
  BTableSimple,
  BThead,
  BFormSelect,
  BTr,
  BTh,
  BTd,
  BTbody,
  BTfoot,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { BBreadcrumb } from "bootstrap-vue";
import axios from "axios";
import Ripple from "vue-ripple-directive";
import FeedBackStatusActive from "./app/list/users-list/FeedBackStatusActive.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
export default {
  components: {
    ToastificationContent,
    FeedBackStatusActive,
    BBreadcrumb,
    BCard,
    BOverlay,
    BFormGroup,
    BAvatar,
    BRow,
    BCol,
    BFormSelect,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BTfoot,
    vSelect,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      addTag: {
        name: "",
      },
      removeButtons: false,
      filterTagByName: [],
      tag: [],
      perPage: 10,
      searchQuery: "",
      currentPage: 1,
      show: false,
      fields: [
        "index",
        { key: "tag", label: "Tag Name" },
        { key: "action", label: "ACTION" },
      ],
      alertMessageToast: "",
      editTag: {
        id: "",
        name: "",
      },
    };
  },
  computed: {
    rows() {
      return this.tag.length;
    },
    submitbutton() {
      return !(this.addTag.name.length > 0);
    },
  },
  mounted() {
    var newPermission = JSON.parse(localStorage.getItem("permission"));
    for (let index = 0; index < newPermission.permission.length; index++) {
      if (newPermission.permission[index] == "view_gallerytags") {
        this.removeButtons = true;
        break;
      } else {
        this.removeButtons = false;
      }
    }
  },
  created() {
    this.getAllTag();
  },
  methods: {
    filterTag() {
      this.show = true;
      setTimeout(() => {
        if (this.searchQuery.trim().length > 0) {
          let finaldata = this.tag.filter((item) => {
            if (item.name) {
              return this.searchQuery
                .toLowerCase()
                .split(" ")
                .every((v) => item.name.toLowerCase().includes(v));
            }
          });
          this.filterTagByName = finaldata;
          this.show = false;
        } else {
          this.filterTagByName = this.tag;
          this.show = false;
        }
      }, 500);
    },
    deleteTagFromServer(tagId) {
      Swal.fire({
        title: "Are you sure?",
        text: "Do You want to delete gallery tag!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.show = true;
          axios
            .delete(
              process.env.VUE_APP_API_URL + `/gallery-tag/delete/${tagId}`,
              {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("_t"),
                },
              }
            )
            .then((response) => {
              if (response.data.message == "Tag deleted successfully") {
                this.alertMessageToast = response.data.message;
                this.tag = response.data.data;
                this.filterTagByName = response.data.data;
                this.show = false;
                this.showToast("success");
              } else {
                this.alertMessageToast = "Tag not deleted try again";
                this.show = false;
                this.showToast("danger");
              }
            });
        }
      });
    },
    editTagFunction() {
      this.show = true;
      axios
        .post(
          process.env.VUE_APP_API_URL + "/gallery-tag/edit-tag",
          this.editTag,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("_t"),
            },
          }
        )
        .then((response) => {
          if (response.data.message == "Tag edit successfully") {
            this.alertMessageToast = response.data.message;
            this.tag = response.data.data;
            this.filterTagByName = response.data.data;
            this.editTag.name = "";
            this.show = false;

            this.showToast("success");
            this.$root.$emit("bv::hide::modal", "edit-tag");
          } else {
            this.editTag.name = "";
            this.show = false;
            this.alertMessageToast = "Tag not edit try again";
            this.showToast("danger");
            this.$root.$emit("bv::hide::modal", "edit-tag");
          }
        });
    },
    editDataFromModel(id, name) {
      this.editTag = {
        id: id,
        name: name,
      };
      this.$root.$emit("bv::show::modal", "edit-tag");
    },
    addTagFunc() {
      this.show = true;
      axios
        .post(process.env.VUE_APP_API_URL + "/gallery-tag/add", this.addTag, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("_t"),
          },
        })
        .then((response) => {
          if (response.data.message == "Tag create successfully") {
            this.alertMessageToast = response.data.message;
            this.filterTagByName = response.data.data;
            this.tag = response.data.data;
            this.addTag.name = "";
            this.show = false;
            this.showToast("success");
            this.$root.$emit("bv::hide::modal", "modal-tag-add");
          } else {
            this.addTag.name = "";
            this.show = false;
            this.alertMessageToast = "Tag not created try again";
            this.showToast("danger");
            this.$root.$emit("bv::hide::modal", "modal-tag-add");
          }
        });
    },
    showToast(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Notification",
          icon: "BellIcon",
          text: this.alertMessageToast,
          variant,
        },
      });
    },
    getAllTag() {
      this.show = true;
      axios
        .get(process.env.VUE_APP_API_URL + "/get-tags/all", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("_t"),
          },
        })
        .then((res) => {
          this.filterTagByName = res.data;
          this.tag = res.data;
          this.show = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-select.scss";
#custom-table td {
  padding: none !important;
}
</style>
